export const promotionTypes = [
  {
    id: 1,
    label: 'pages.businessGuide.promotion.addDialog.types.instagram',
    value: 'instagram',
    imgPath: require('@/assets/img/icons/story-mode/promotional-activities/instagram.svg'),
    avatarImg: require('@/assets/img/icons/story-mode/promotional-activities/w_instagram.svg')
  },
  {
    id: 2,
    label: 'pages.businessGuide.promotion.addDialog.types.facebook',
    value: 'facebook',
    imgPath: require('@/assets/img/icons/story-mode/promotional-activities/facebook.svg'),
    avatarImg: require('@/assets/img/icons/story-mode/promotional-activities/w_facebook.svg')
  },
  {
    id: 3,
    label: 'pages.businessGuide.promotion.addDialog.types.gAds',
    value: 'g-ads',
    imgPath: require('@/assets/img/icons/story-mode/promotional-activities/g-ads.svg'),
    avatarImg: require('@/assets/img/icons/story-mode/promotional-activities/w_google.svg')
  },
  {
    id: 4,
    label: 'pages.businessGuide.promotion.addDialog.types.website',
    value: 'website',
    imgPath: require('@/assets/img/icons/story-mode/promotional-activities/website.svg'),
    avatarImg: require('@/assets/img/icons/story-mode/promotional-activities/w_webpage.svg')
  },
  {
    id: 5,
    label: 'pages.businessGuide.promotion.addDialog.types.press',
    value: 'press',
    imgPath: require('@/assets/img/icons/story-mode/promotional-activities/press.svg'),
    avatarImg: require('@/assets/img/icons/story-mode/promotional-activities/w_press.svg')
  },
  {
    id: 6,
    label: 'pages.businessGuide.promotion.addDialog.types.tvAndRadio',
    value: 'tv-and-radio',
    imgPath: require('@/assets/img/icons/story-mode/promotional-activities/television.svg'),
    avatarImg: require('@/assets/img/icons/story-mode/promotional-activities/w_television.svg')
  }, {
    id: 7,
    label: 'pages.businessGuide.promotion.addDialog.types.personalSales',
    value: 'personal-sales',
    imgPath: require('@/assets/img/icons/story-mode/promotional-activities/personal sales.svg'),
    avatarImg: require('@/assets/img/icons/story-mode/promotional-activities/w-personal-sales.svg')
  },
  {
    id: 8,
    label: 'pages.businessGuide.promotion.addDialog.types.publicRelations',
    value: 'public-relations',
    imgPath: require('@/assets/img/icons/story-mode/promotional-activities/public relations.svg'),
    avatarImg: require('@/assets/img/icons/story-mode/promotional-activities/w-public-relations.svg')
  },
  {
    id: 9,
    label: 'pages.businessGuide.promotion.addDialog.types.directMail',
    value: 'direct-mail',
    imgPath: require('@/assets/img/icons/story-mode/promotional-activities/direct email.svg'),
    avatarImg: require('@/assets/img/icons/story-mode/promotional-activities/w-direct-mail.svg')
  },
  {
    id: 10,
    label: 'pages.businessGuide.promotion.addDialog.types.salesPromotion',
    value: 'sales-promotion',
    imgPath: require('@/assets/img/icons/story-mode/promotional-activities/sales promotion.svg'),
    avatarImg: require('@/assets/img/icons/story-mode/promotional-activities/w-sales-promotion.svg')
  },
  {
    id: 11,
    label: 'pages.businessGuide.promotion.addDialog.types.brochuresAndFlyers',
    value: 'brochures-and-flyers',
    imgPath: require('@/assets/img/icons/story-mode/promotional-activities/brochures and flyers.svg'),
    avatarImg: require('@/assets/img/icons/story-mode/promotional-activities/w_brochure.svg')
  },
  {
    id: 12,
    label: 'pages.businessGuide.promotion.addDialog.types.events',
    value: 'events',
    imgPath: require('@/assets/img/icons/story-mode/promotional-activities/events.svg'),
    avatarImg: require('@/assets/img/icons/story-mode/promotional-activities/w_events.svg')
  },
  {
    id: 13,
    label: 'pages.businessGuide.promotion.addDialog.types.wordOfMouth',
    value: 'word-of-mouth',
    imgPath: require('@/assets/img/icons/story-mode/promotional-activities/word of mouth.svg'),
    avatarImg: require('@/assets/img/icons/story-mode/promotional-activities/w-word-of-mouth.svg')
  },
  {
    id: 14,
    label: 'pages.businessGuide.promotion.addDialog.types.outdoorAdvertising',
    value: 'outdoor-advertising',
    imgPath: require('@/assets/img/icons/story-mode/promotional-activities/outdoor.svg'),
    avatarImg: require('@/assets/img/icons/story-mode/promotional-activities/w_outdoor.svg')
  },
  {
    id: 15,
    label: 'pages.businessGuide.promotion.addDialog.types.otherPromotion',
    value: 'other-promotion',
    imgPath: require('@/assets/img/icons/story-mode/promotional-activities/other.svg'),
    avatarImg: require('@/assets/img/icons/story-mode/promotional-activities/w_other.svg')
  }
]
